// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e5e9f4;
$primaryMain: #001f8d;
$primaryDark: #001f8d;
$primary200: #56ccf2;
$primary800: #1565c0;

// secondary
$secondaryLight: #81b4d0;
$secondaryMain: #cb3e19;
$secondaryDark: #cb3e19;
$secondary50: #e3f2fd;
$secondary100: #81b4d0;
$secondary200: #0284c7;
$secondary800: #011520;

// success Colors
$successLight: #b9f6ca;
$success200: #b9f6ca;
$successMain: #69f0ae;
$successDark: #00c853;

// error
$errorLight: #fbe9e7;
$errorMain: #d84315;
$errorDark: #d84315;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #ffcb82;
$warningMain: #ffcb82;
$warningDark: #d84315;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey400: #E9E1D5;
$grey500: #9e9e9e;
$grey600: #828282;
$grey700: #757575;
$grey800: #6a5757;
$grey900: #212121;
$greyA200: #5C5959;
$greyA300: #2a2a2a;


// purple
$main: #090f25;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4
$lightPaper: #f7f9f9;

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #f7f9f9;
$darkPrimaryMain: #ffa800;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary100: #EDE7F6;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #45110b;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary50: $secondary50;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;
  greyA200: $greyA200;
  greyA300: $greyA300;

  // purple
  main: $main;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;
  lightPaper: $lightPaper;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary100: $darkSecondary100;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
